import React from "react"
import { Flex, Box, Heading, Button, Text, Link } from "rebass"

const Logo = () => (
  <svg
    height="34px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50.21 71.53"
  >
    <g data-name="Layer 2">
      <path
        d="M42.31 7.9A23.39 23.39 0 0025.64 1h-4.72a6.61 6.61 0 00-6.62 6.6.43.43 0 000 .05h0v6.56h11.34a10.36 10.36 0 010 20.71 6.61 6.61 0 000 13.22A23.57 23.57 0 0042.31 7.9zm-28.13 6.4H7.6A6.61 6.61 0 001 20.91v43a6.61 6.61 0 1013.22 0V14.28z"
        fill="none"
        stroke="#4C55FF"
        stroke-width="1.98"
        data-name="Layer 1"
      />
    </g>
  </svg>
)

const DesktopLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 533.81 145.3"
    height="34px"
  >
    <g data-name="Layer 2">
      <path
        d="M84.81 16.15A46.41 46.41 0 0051.72 2.39h-9.37a13.16 13.16 0 00-13.13 13.18v.1h0v13.06h22.5a20.6 20.6 0 0120.55 20.65A20.62 20.62 0 0151.72 70a13.18 13.18 0 000 26.35 47 47 0 0033.09-80.2zm-56.9 13.07H15.18A13 13 0 002.39 42.41v85.9a13 13 0 0012.79 13.19A13 13 0 0028 128.31l-.07-99.09zm111.14 86.43h-12.72a13 13 0 01-12.8-13.19v-85a12.8 12.8 0 1125.59 0l-.07 98.19zm1.14 1.31v12.72a12.92 12.92 0 0013 12.79h24.4a12.79 12.79 0 100-25.58l-37.44.07zM300 50.63a47 47 0 00-13.71-33.35 46.32 46.32 0 00-33.14-13.81h-9.37a13.18 13.18 0 00-13.13 13.22v.11h0v13.11h22.5A20.37 20.37 0 01267.69 36a20.64 20.64 0 016 14.64v78.74a13.13 13.13 0 1026.25 0l-.07-78.73zM249.44 72.2h-19.48V30.26h-13a13.15 13.15 0 00-13.09 13.2v85.9a13.09 13.09 0 1026.18 0v-30.8h19.46a13.2 13.2 0 000-26.39zm164.85-21.57h.06a47 47 0 00-13.7-33.35 46.34 46.34 0 00-33.09-13.81h-9.38a13.17 13.17 0 00-13.12 13.22v.11h0v13.11h22.5A20.33 20.33 0 01382.09 36a20.68 20.68 0 016 14.65v78.73a13.12 13.12 0 1026.24 0l-.06-78.73zM331 30.29a13 13 0 00-12.79 13.2v85.9a12.8 12.8 0 1025.58 0l-.06-99.09zm158.84 51.2l37.73-37.55a13.06 13.06 0 10-18.47-18.45l-28.43 28.38a13 13 0 000 18.45c.06.07 9.19 9.17 9.19 9.17zm13.59 15.13a14.81 14.81 0 00-1-1.11L489.94 83l-18.44 18.44h0l37.5 37.65a13 13 0 0018.44-18.45l-24-24zm-44-7.79V38.5 16.74a12.9 12.9 0 10-25.79 0V129.4a12.9 12.9 0 1025.79 0V113l10.61-12.07-10.59-12.1z"
        fill="none"
        stroke="#4C55FF"
        stroke-width="4.78"
        data-name="Layer 1"
      />
    </g>
  </svg>
)

export default function SkinnyNavbar() {
  return (
    <Flex
      flexDirection="column"
      sx={{
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: "1000",
        backgroundColor: "red",
      }}
    >
      <Flex
        px={[4, 5]}
        py={3}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <a href="/" style={{ textDecoration: "none" }}>
            <DesktopLogo />
          </a>
        </Box>

        <Box mx="auto" />
        <Flex alignItems="center" id="navBar">
          <Link variant="nav" id="navLink">
            About Us
          </Link>
          <Link variant="nav" id="navLink">
            Services
          </Link>
          <Link variant="nav" id="navLink">
            Case Studies
          </Link>
          <a href="/contact" style={{ textDecoration: "none" }}>
            <Button variant="primaryNav" display={["none", "flex"]} ml={3}>
              Contact
            </Button>
          </a>
        </Flex>
      </Flex>
    </Flex>
  )
}
